import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Constrain from "../../../../components/constrain"
import Heading from "../../../../components/heading"
import Image from "../../../../components/image"
import ImageHotspot from "../../../../components/image-hotspot"
import ImageHotspots from "../../../../components/image-hotspots"
import Paragraph from "../../../../components/paragraph"
import Stack from "../../../../components/stack"

const ManuskriptFruehling = (props) => {
  const data = useStaticQuery(graphql`
    query ManuskriptFruehlingQuery {
      gedicht: file(
        relativePath: {
          eq: "dauerausstellung/05-haelfte-des-lebens/gedicht-fruehling.png"
        }
      ) {
        ...largeImage
      }
      fruehling01: file(
        relativePath: {
          eq: "dauerausstellung/05-haelfte-des-lebens/fruehling-01.jpg"
        }
      ) {
        ...largeImage
      }
      fruehling02: file(
        relativePath: {
          eq: "dauerausstellung/05-haelfte-des-lebens/fruehling-02.jpg"
        }
      ) {
        ...largeImage
      }
      fruehling03: file(
        relativePath: {
          eq: "dauerausstellung/05-haelfte-des-lebens/fruehling-03.jpg"
        }
      ) {
        ...largeImage
      }
      fruehling04: file(
        relativePath: {
          eq: "dauerausstellung/05-haelfte-des-lebens/fruehling-04.jpg"
        }
      ) {
        ...largeImage
      }
      fruehling05: file(
        relativePath: {
          eq: "dauerausstellung/05-haelfte-des-lebens/fruehling-05.jpg"
        }
      ) {
        ...largeImage
      }
      fruehling06: file(
        relativePath: {
          eq: "dauerausstellung/05-haelfte-des-lebens/fruehling-06.jpg"
        }
      ) {
        ...largeImage
      }
    }
  `)

  const ModalTemplate = (props) => {
    const { title, image, children } = props
    return (
      <Stack>
        <Constrain>
          <Stack>
            <Heading level={3}>{title}</Heading>
            {image}
            {children}
          </Stack>
        </Constrain>
      </Stack>
    )
  }

  return (
    <Stack space={3}>
      <ImageHotspots
        caption="Hölderlins Gedichtmanuskript ›Der Frühling.‹ aus dem Deutschen Literaturarchiv Marbach"
        image={
          <Image
            image={data.gedicht.childImageSharp.gatsbyImageData}
            alt="Handschriftliches Manuskript von Hölderlins ›Der Frühling.‹"
          />
        }
      >
        <ImageHotspot x={37} y={10} name="fruehling-01">
          <ModalTemplate
            title="Der Frühling. kommt"
            image={
              <Image
                image={data.fruehling01.childImageSharp.gatsbyImageData}
                alt="vergrößerter Ausschnitt des Gedichttitels und der ersten Zeile mit der Ergänzung »kommt«"
              />
            }
          >
            <Paragraph>
              Hölderlin beginnt sein Gedicht mit dem Titel: ›Der Frühling‹.
              Danach setzt er erst einmal einen Punkt. Er macht eine Pause und
              ruft sich die Bilder des Frühlings vor Augen. Dann beginnt er mit
              dem 1. Vers, in dem er vor lauter Eile ein Wort vergisst, das er
              erst später zwischen den Titel und den 1. Vers einfügt: »kommt«.
            </Paragraph>
          </ModalTemplate>
        </ImageHotspot>
        <ImageHotspot x={7} y={70} name="klecks">
          <ModalTemplate
            title="o – so"
            image={
              <Image
                image={data.fruehling02.childImageSharp.gatsbyImageData}
                alt="Tintenkleckse auf dem Manuskript"
              />
            }
          >
            <Paragraph>
              Zu Beginn des letzten Verses taucht Hölderlin seine Schreibfeder
              noch einmal tief ins Tintenfass. Als er sie dann wieder auf dem
              Papier aufsetzt, hinterlässt sie erst einmal einen dicken
              Tintenklecks. Von dem Wort »so« bleibt damit nur das »o« zurück.
            </Paragraph>
          </ModalTemplate>
        </ImageHotspot>
        <ImageHotspot x={53} y={80} name="scardanelli">
          <ModalTemplate
            title="Scardanelli"
            image={
              <Image
                image={data.fruehling03.childImageSharp.gatsbyImageData}
                alt="Hölderlins Signatur mit dem Pseudonym »Scardanelli«"
              />
            }
          >
            <Paragraph>
              Nachdem er sein Gedicht fertiggestellt hat, hinterlässt Hölderlin
              darauf noch eine Unterschrift. Er unterschreibt aber nicht mit
              »Friedrich Hölderlin«. Nein, er schreibt: »Mit Unterthänigkeit
              Scardanelli«.
            </Paragraph>
            <Paragraph>
              Den Namen »Scardanelli« hat sich Hölderlin selbst ausgedacht. Mit
              seinem echten Namen wollte der alte Hölderlin nicht mehr
              angesprochen werden. Erst später hat jemand in Klammern darunter
              geschrieben: »J.(ohann) C.(hristian) F.(riedrich) Hölderlin«.
            </Paragraph>
          </ModalTemplate>
        </ImageHotspot>
        <ImageHotspot x={73} y={93} name="geburtstag">
          <ModalTemplate
            title="Ein Geburtstagsgedicht?"
            image={
              <Image
                image={data.fruehling04.childImageSharp.gatsbyImageData}
                alt="Datumsvermerk des späteren Besitzers"
              />
            }
          >
            <Paragraph>
              Zu Hölderlins »74ten Geburtstag« soll das Gedicht angeblich
              entstanden sein. Das behauptet zumindest diese Notiz. Doch zurecht
              hat jemand anderes später mit Bleistift ein Fragezeichen dahinter
              gesetzt und die 4 durch eine 3 korrigiert. Seinen 74. Geburtstag
              hat Hölderlin nämlich nicht mehr erlebt. Er ist im Alter von 73
              Jahren an einem Sommertag im Juni 1843 gestorben.
            </Paragraph>
          </ModalTemplate>
        </ImageHotspot>
        <ImageHotspot x={17} y={82} name="datum">
          <ModalTemplate
            title="Eine merkwürdige Datierung"
            image={
              <Image image={data.fruehling05.childImageSharp.gatsbyImageData} />
            }
          >
            <Paragraph>
              Hölderlin hinterlässt auf dem Papier auch ein Datum: Am 24. Mai
              1758 soll das Gedicht entstanden sein. Doch Moment mal! Hölderlins
              Geburtstag war doch der 20. März 1770. Das Gedichte wäre somit 12
              Jahre vor seiner Geburt entstanden. Da kann doch etwas nicht
              stimmen! Tatsächlich hat sich Hölderlin in den Gedichten, die er
              im Turm geschrieben hat, nicht mehr an den geltenden Kalender
              gehalten.
            </Paragraph>
          </ModalTemplate>
        </ImageHotspot>
        <ImageHotspot x={20} y={90} name="provenienznachweis">
          <ModalTemplate
            title="Die Geschichte des Manuskripts"
            image={
              <Image
                image={data.fruehling06.childImageSharp.gatsbyImageData}
                alt="Handschriftliche Anmerkung des späteren Besitzers"
              />
            }
          >
            <Paragraph>
              Nach Hölderlins Tod wechselt das Manuskript noch einige Male seine
              Besitzer. Einer davon hat hier aufgeschrieben, wie das Blatt
              Papier zu ihm gelangt ist. Das nennt man einen
              Provenienz-Nachweis.
            </Paragraph>
          </ModalTemplate>
        </ImageHotspot>
      </ImageHotspots>
    </Stack>
  )
}

export default ManuskriptFruehling
