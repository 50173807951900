import React from "react"
import { getCourseNavigation } from "../../../../../store/courses"
import LayoutCourse from "../../../../../layouts/course"

import Box from "../../../../../components/box"
import Paragraph from "../../../../../components/paragraph"
import CourseFooterNext from "../../../../../components/course-footer-next"
import Heading from "../../../../../components/heading"
import Icon from "../../../../../components/icon"
import Note from "../../../../../components/note"
import Seo from "../../../../../components/seo"
import Stack from "../../../../../components/stack"

import ManuskriptFruehling from "../../../../../content/kurse/jahreszeiten/01-jahreszeitengedicht/manuskript-fruehling"
import TaskSidebar from "../../../../../components/task-sidebar"

const Page = () => {
  const navigation = getCourseNavigation({ courseId: "jahreszeiten" })

  return (
    <LayoutCourse
      navigation={navigation}
      footer={
        <CourseFooterNext to="/kurse/jahreszeiten/01-jahreszeitengedicht/gedicht-hoeren" />
      }
    >
      <Seo title="Manuskript" />
      <Stack>
        <Heading as="h1" level={2}>
          Das Manuskript erforschen
        </Heading>
        <Paragraph>
          Aus einem Manuskript kann man viel über die Entstehung eines Textes
          erfahren. Es ist fast so, als könnte man dem Autor beim Schreiben über
          die Schulter sehen und beobachten, wie er beim Schreiben vorgegangen
          ist und wo er Wörter gestrichen und korrigiert hat.
        </Paragraph>
        <TaskSidebar
          main={<ManuskriptFruehling />}
          sidebar={
            <Note variant="task">
              <>
                Sieh dir das Manuskript einmal genauer an. Mit einem Klick auf
                das
                <Box
                  as="span"
                  display="inline-flex"
                  ml={1}
                  bg="primary"
                  color="background"
                  borderRadius="full"
                >
                  <Icon size={4} icon="plus" />
                </Box>{" "}
                Zeichen erfährst du mehr über die Details.
              </>
            </Note>
          }
        />
      </Stack>
    </LayoutCourse>
  )
}

export default Page
